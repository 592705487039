<template>
    <div class="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6 mt-8">
        <div class="flex flex-wrap md:grid md:grid-cols-2 md:gap-4 items-center">
            <SwitchGroup class="mb-4 md:mb-0">
                <div class="flex lg:flex-wrap items-center justify-start w-full">
                    <Switch
                            v-model="slotsStore.policyAgree"
                            :class="slotsStore.policyAgree ? 'bg-pink-500' : 'bg-gray-200'"
                            class="relative inline-flex h-6 w-[50px] md:w-[140px] min-w-[50px] max-w-[45px] lg:mb-2 items-center rounded-full transition-colors focus:outline-none grow shrink-0"
                            style="min-width: 40px"
                            @click="slotsStore.policyAgree = !slotsStore.policyAgree"
                    >
            <span
                    :class="
                slotsStore.policyAgree ? 'translate-x-6' : 'translate-x-1'
              "
                    class="inline-block h-4 w-4 transform rounded-full bg-white transition-transform"
            />
                    </Switch>
                    <SwitchLabel class="ml-4 lg:ml-0 text-xs w-full"
                    >Нажимая на кнопку записаться, я даю согласие на обработку
                        персональных данных
                    </SwitchLabel>
                </div>
            </SwitchGroup>

            <div
                    v-if="type !== 'order'"
                    @click="book(loader, valid, slotsStore.policyAgree)"
                    class="inline-flex justify-center rounded-full border border-transparent py-5 px-8 w-full md:max-w-[240px] tracking-wider text-base uppercase font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                    :class="{
          'bg-gray-200 cursor-not-allowed':
            loader || !valid || !slotsStore.policyAgree,
          'bg-pink-600 focus:ring-pink-600 hover:bg-pink-700 cursor-pointer':
            !loader && valid && slotsStore.policyAgree,
        }"
            >
                {{ loader ? "Отправка" : "Записаться" }}
            </div>
            <div
                    v-else
                    @click="order(loader, valid, slotsStore.policyAgree)"
                    class="inline-flex justify-center rounded-full border border-transparent py-5 px-8 w-full md:max-w-[240px] tracking-wider text-base uppercase font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                    :class="{
          'bg-gray-200 cursor-not-allowed':
            loader || !valid || !slotsStore.policyAgree,
          'bg-pink-600 focus:ring-pink-600 hover:bg-pink-700 cursor-pointer':
            !loader && valid && slotsStore.policyAgree,
        }"
            >
                {{ loader ? "Отправка" : "Отправить" }}
            </div>
        </div>
    </div>
</template>

<script>
import {Switch, SwitchLabel, SwitchGroup} from "@headlessui/vue";

import {useSlots} from "../../store/slots";
import {useEmail} from "../../store/email";
import {useClinics} from "../../store/clinics";
import {useStat} from "../../store/stat";
import {useUi} from "../../store/ui";

export default {
    props: ["type"],
    data() {
        return {
            loader: false,
        };
    },
    setup() {
        const slotsStore = useSlots();
        const emailStore = useEmail();
        const clinicStore = useClinics();
        const statStore = useStat();
        const uiStore = useUi();

        return {
            emailStore,
            slotsStore,
            clinicStore,
            statStore,
            uiStore
        };
    },
    methods: {
        async order(loader = true, valid = false, policyAgree = false) {
            if (!loader && valid && policyAgree) {
                this.loader = true;
                if (this.slotsStore.type === 'home') {
                    await this.emailStore.send("orderhome", this.slotsStore.patient);
                } else {
                    await this.emailStore.send("order", this.slotsStore.patient);
                }
                this.statStore.goal('order');
                const callTouchData = this.callTouchData('Заявка');
                this.statStore.callTouch({...callTouchData, tag: 'order'});
                this.slotsStore.sendOrder = true;
                this.loader = false;
            }
        },
        async book(loader = true, valid = false, policyAgree = false) {
            if (!loader && valid && policyAgree) {
                this.loader = true;
                let book;
                if (this.slotsStore.target === "home") {
                    book = await this.slotsStore.bookHome();
                    if (book) {
                        this.statStore.goal('home_success');
                        const callTouchData = this.callTouchData('Вызов на дом');
                        this.statStore.callTouch({...callTouchData, tag: 'home'});
                    }
                } else {
                    book = await this.slotsStore.book();
                    if (book) {
                        this.statStore.yandex({action: 'book'});
                        const callTouchData = this.callTouchData('Запись на прием');
                        this.statStore.callTouch({...callTouchData, tag: 'book'});
                    }
                }
                let patient = {...this.slotsStore.patient};
                if (patient.reason) {
                    patient.comment = patient.reason + '. ' + patient.comment;
                }

                if (book) {
                    await this.emailStore.send(
                        this.slotsStore.typeVisit === "home" ? "home" : "slot",
                        {
                            ...patient,
                            ...book,
                        }
                    );
                    this.slotsStore.complete = true;
                    this.loader = false;
                } else {
                    this.slotsStore.open = false;
                    this.uiStore.openError();
                    this.loader = false;
                }
            }
        },
        callTouchData(subject) {
            return {
                fio: this.slotsStore?.patient?.fio,
                phone: this.slotsStore?.patient?.phone,
                subject: subject,
                requestUrl: window?.location,
            }
        },
    },
    watch: {
        valid() {
            this.slotsStore.policyAgree = this.valid;
        },
    },
    computed: {
        valid() {
            if (this.type === "order") {
                if (this.slotsStore.patient.fio && this.slotsStore.patient.phone) {
                    return true;
                }
            } else {
                if (
                    this.slotsStore.patient.fio &&
                    this.slotsStore.patient.phone &&
                    this.slotsStore.patient.birthday.day &&
                    this.slotsStore.patient.birthday.month &&
                    this.slotsStore.patient.birthday.year &&
                    this.slotsStore.date &&
                    this.slotsStore.time
                ) {
                    return true;
                }
            }
            return false;
        },
    },

    components: {
        Switch,
        SwitchLabel,
        SwitchGroup,
    },
};
</script>
